import { Component, OnDestroy, OnInit } from '@angular/core';
import { IDi, IDiTransport } from '../../interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../../components';
import { TransportService } from '../../services/transport.service';
import { DI_TRANSPORT_STATE } from '../../config';
import { AlertService } from 'src/app/auth/services/alert/alert.service';
import { RoutingService } from '../../services/routing.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReloadListDiService } from '../../shared/reaload-list-di/reload-list-di.service';
import { DeclineDiModalComponent } from '../../modals/decline-di/decline-di-modal.component';
import { environment } from 'src/environments/environment';
import { DocumentsService } from '../../services/documents.service';

@Component({
  selector: 'gesres-di-details',
  templateUrl: './di-details.component.html',
  styleUrls: ['./di-details.component.css'],
})
export class DiDetailsComponent implements OnInit, OnDestroy {
  public id: number = 0;
  public di?: IDi;
  public form!: FormGroup;
  public alive: any;

  public pathEmbed: string | null = null;
  public urlPath: string | null = null; // Hacer pública la variable urlPath

  constructor(
    public dialog: MatDialog,
    private transportService: TransportService,
    private documentService: DocumentsService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private routingService: RoutingService,
    private fb: FormBuilder,
    private reloadListDi: ReloadListDiService
  ) {}

  public ngOnInit(): void {
    this.route.params.subscribe((routeParams) => {
      this.id = Number(routeParams['id']);

      this.alertService.showLoading();

      if (this.id == 0) {
        this.router.navigate(['../'], { relativeTo: this.route });
      }

      this.ngBuildForm();
      this.ngDetails();
    });
  }

  public ngOnDestroy(): void {
    this.reloadListDi.onReload(true);
    this.routingService.ngHiddenRoutingBack();
  }

  public onClick(send: boolean): void {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.action) {
        this.ngSendNotification(send);
      }
    });
  }

  public onSend(): void {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.action) {
        var transport: IDiTransport = {
          state_action: this.di?.transport_state_action!,
          device_id: null,
          quantity_received: Number(this.quantity_received.value),
          license_plate: String(this.license_plate.value),
        };

        this.transportService.update(this.id, transport).subscribe(
          (response: IDi) => {
            this.ngDetails();
          },
          (error) => {}
        );
      }
    });
  }

  public onReload(): void {
    this.ngDetails(true);
  }

  private ngDetails(loading: boolean = false): void {
    if (loading) {
      this.alertService.showLoading();
    }

    this.transportService.details(this.id).subscribe(
      (di: IDi) => {
        this.di = di;
        this.urlPath = di.di_document_path;

        this.quantity_received.setValue(this.di.transport_quantity_received);

        this.alertService.hideLoading();
      },
      (error) => {
        this.router.navigate(['../'], { relativeTo: this.route });
      }
    );
  }

  public onDownloadDocument(): void {
    if (this.urlPath) {
      this.alertService.showLoading();
      this.ngLoadDocument(this.urlPath);
    }
  }

  private ngLoadDocument(path: string): void {
    const fullPath = `${environment.base_api_url_gesres}${path}`;

    this.documentService.show(fullPath).subscribe(
      (response: Blob) => {
        // Forzar el tipo de contenido a 'application/pdf'
        const pdfBlob = new Blob([response], { type: 'application/pdf' });

        const url = window.URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = path.split('/').pop()!;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        this.alertService.hideLoading();
      },
      (error) => {
        console.log(error);
        this.alertService.hideLoading();
      }
    );
  }

  public openModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.di;

    const dialogRef = this.dialog.open(DeclineDiModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((decline) => {
      if (decline) {
        this.alertService.showLoading();
        console.log('decline.description:' + decline.description);
        var transport: IDiTransport = (transport = {
          state_action: 'rechazada',
          description: decline.description,
          device_id: null,
        });
        this.transportService.update(this.id, transport).subscribe(
          (response: IDi) => {
            this.alertService.hideLoading();
            this.router.navigate(['../'], { relativeTo: this.route });
          },
          (error) => {
            this.alertService.hideLoading();
            this.router.navigate(['../'], { relativeTo: this.route });
          }
        );
      }
    });
  }

  private ngSendNotification(action: boolean): void {
    var transport: IDiTransport = (transport = {
      state_action: action
        ? DI_TRANSPORT_STATE.ACEPTADA
        : DI_TRANSPORT_STATE.CANCELADA,
      device_id: null,
    });

    this.transportService
      .update(this.id, transport)
      .subscribe((transport: IDi) => {
        if (transport.state_action == DI_TRANSPORT_STATE.CANCELADA) {
          this.router.navigate(['../'], { relativeTo: this.route });
        } else {
          this.ngDetails();
        }
      });
  }

  private ngBuildForm(): void {
    this.form = this.fb.group({
      quantity_received: ['', Validators.required],
      license_plate: ['', Validators.required],
    });
  }

  get quantity_received() {
    return this.form.get('quantity_received')!;
  }

  get license_plate() {
    return this.form.get('license_plate')!;
  }
}
