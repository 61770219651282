<div (swipedown)="onReload()">
  <mat-card class="gesres-address mb-2">
    <mat-card-title>
      <span>Información</span>
    </mat-card-title>
    <mat-card-content>
      <h5 class="mb-1 fs-6 font-weight-medium color-info">
        {{ di?.di_external }}
      </h5>
      <p>
        <i class="far fa-calendar-alt text-secondary me-2"></i
        >{{ di?.date_relocation_init | date }}
      </p>
    </mat-card-content>
    <mat-card-footer>
      <div class="ngx-state-banner" [ngClass]="di?.transport_state_action">
        {{ di?.transport_state_action! | state }}
      </div>
    </mat-card-footer>
  </mat-card>

  <mat-card class="gesres-address mb-2" *ngIf="di?.ler_id">
    <mat-card-title>
      <span>Ler <span class="mb-1 fs-6 font-weight-medium color-info">{{ di?.ler_code }}</span></span>
    </mat-card-title>
    <mat-card-content>
      <address class="">
        {{ di?.ler_description }}
      </address>
      <address class="mb-0">
        <p class="mb-0">
          <strong>Cantidad: </strong>{{ di?.ler_quantity_received }} Kg
        </p>
      </address>
    </mat-card-content>
  </mat-card>

  <mat-card class="gesres-address constructor-site mb-2">
    <mat-card-title>
      <span>Origen</span>
    </mat-card-title>
    <mat-card-content>
      <h5 class="mb-1 fs-6 font-weight-medium color-info">
        {{ di?.constructor_site_name }}
      </h5>
      <address class="mb-0">
        <i class="fas fa-map-marker-alt"></i>
        {{ di?.constructor_site_address }} - {{ di?.constructor_site_cp }}
      </address>
      <address class="mb-0">
        {{ di?.constructor_site_town }} ({{ di?.constructor_site_province }})
      </address>
    </mat-card-content>
  </mat-card>

  <mat-card class="gesres-address waste-center mb-2">
    <mat-card-title>
      <span>Destino</span>
    </mat-card-title>
    <mat-card-content>
      <h5 class="mb-1 fs-6 font-weight-medium color-info">
        {{ di?.waste_center_name }}
      </h5>
      <address class="mb-0">
        <i class="fas fa-map-marker-alt"></i>
        {{ di?.waste_center_address }} - {{ di?.waste_center_cp }}
      </address>
      <address class="mb-0">
        {{ di?.waste_center_town }} ({{ di?.waste_center_province }})
      </address>
    </mat-card-content>
  </mat-card>

  <mat-card
    class="gesres-address mb-2"
    *ngIf="
      di?.transport_state_action == 'aceptada' ||
      di?.transport_state_action == 'en_transito' ||
      di?.transport_state_action == 'entregada'
    "
  >
    <mat-card-title>
      <span>{{ "Datos del Transporte" }}</span>
    </mat-card-title>
    <mat-card-content>
      <div *ngIf="!di?.transport_quantity_received && !di?.license_plate">
        <form [formGroup]="form">
          <div class="input-group mb-1">
            <input
              type="text"
              class="form-control"
              aria-label="carga recibida"
              placeholder="Cantidad recogida Kg"
              aria-describedby="quantity_received"
              formControlName="quantity_received"
              [OnlyNumberAndTwoDecimals]="'^([1-9]\\d*|0)?(\\.\\d{0,2})?$'"
            />
          </div>
          <div class="input-group mb-1">
            <input
              type="text"
              placeholder="Matrícula"
              class="form-control"
              aria-label="license plate"
              aria-describedby="license_plate"
              formControlName="license_plate"
            />
          </div>
          <button
            class="btn btn-info w-100"
            type="button"
            id="submit"
            (click)="onSend()"
          >
            Enviar
          </button>
        </form>
      </div>
      <div class="d-flex flex-column" *ngIf="di?.transport_quantity_received && di?.license_plate">
        <div class="d-flex align-items-center mb-2">
          <i class="fas fa-balance-scale text-secondary me-2"></i>
          <span class="text-secondary">{{ di?.transport_quantity_received }} Kg</span>
        </div>
        <div class="d-flex align-items-center mb-2">
          <i class="fas fa-truck text-secondary me-2"></i>
          <span class="text-secondary">{{ di?.license_plate }}</span>
        </div>
        <div class="d-flex align-items-center mb-2">
          <i class="fas fa-clock text-secondary me-2"></i>
          <span class="text-secondary">{{ di?.transport_received_date | date: 'dd-MM-yyyy hh:mm'}}</span>
        </div>
      </div>      
    </mat-card-content>
  </mat-card>

  <mat-card class="mb-2" *ngIf="di?.transport_quantity_received && di?.license_plate">
    <mat-card-title>
      <span>Documento</span>
    </mat-card-title>
    <mat-card-content>
      <h5 *ngIf="urlPath" class="mb-1 fs-6 font-weight-medium color-info">
        <a (click)="onDownloadDocument()"> Descargar</a>
      </h5>
      <h5 *ngIf="!urlPath" class="mb-1 fs-6 font-weight-medium color-info">
        No hay documento disponible para descargar
      </h5>
    </mat-card-content>
  </mat-card>

</div>
