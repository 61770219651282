<div *ngIf="route.children.length === 0">
  <ul class="ngx-swipe-list" (swipedown)="onReload($event)" >
    <li class="ngx-swipe-list-item" *ngFor="let item of diList; let i = index">
      <mat-card class="mb-4" (click)="onClick(item)">
        <mat-card-content>
          <div
            class="ngx-swipe-track mb-0 py-2 d-flex justify-content-between align-items-center"
          >
            <div>
              <i class="fas fa-truck-loading me-2 gesres-title-constructor color-info"></i
              ><span class="color-info fw-5 gesres-title-constructor">{{ item.constructor_site_name }}</span>
            </div>

            <span class="ngx-swipe-title mb-0 text-secondary">
              {{ item.di_external }}
            </span>

          </div>
          <hr class="mt-0 mb-2" />
          <div class="ngx-swipe-track">
            <div class="ngx-swipe-to mb-2">
              <p class="mb-2 text-secondary">
                <i class="fas fa-map-marker-alt icon-default"></i>               
                {{ item.constructor_site_address }} -
                {{ item.constructor_site_town }} -
                {{ item.constructor_site_cp }} ({{
                  item.constructor_site_province
                }})
              </p>
              <div class="d-flex justify-content-between mb-2">
                <span>
                  <span class="text-secondary">Ler: </span
                  ><span class="fw-4">{{ item.ler_code }}</span>
                </span>
                <span>
                  <i class="fas fa-balance-scale me-1 text-secondary"></i>
                  <span class="fw-4">{{ item.ler_quantity_received }} Kg</span>
                </span>
                <span>
                  <i class="fas fa-calendar-alt me-2 text-secondary"></i
                  ><span class="fw-4">{{ item.date_relocation_init | date }}</span>
                </span>
              </div>
            </div>
            <div class="ngx-swipe-from mb-2">
              <p class="mb-0">
                <span class="fs-8 me-1 text-secondary">Destino:</span>
                <span class="">{{ item.waste_management_center_name }}</span>
              </p>
              <p class="mb-0">
                <i class="fas fa-map-marker-alt icon-default"></i>
                {{ item.waste_management_center_address }} -
                {{ item.waste_management_center_town }} -
                {{ item.waste_management_center_cp }} ({{
                  item.waste_management_center_province
                }})
              </p>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer>
          <div
            class="ngx-actions d-flex"
            *ngIf="item.transport_state_action == 'pendiente'"
          >
            <button
              type="button"
              (click)="onSelect($event, 'rechazada', item)"
              class="btn btn-danger font-weight-medium px-2 w-100 rounded-0"
            >
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class="fa fa-times feather feather-send feather-sm fill-white me-2"
                ></i>
                {{ "Rechazar" }}
              </div>
            </button>
            <button
              type="button"
              (click)="onSelect($event, 'aceptada', item)"
              class="btn btn-info font-weight-medium px-2 w-100 rounded-0"
            >
              <div class="d-flex align-items-center justify-content-center">
                <i
                  class="fa fa-paper-plane feather feather-send feather-sm fill-white me-2"
                ></i>
                {{ "Aceptar" }}
              </div>
            </button>
          </div>

          <div
            class="ngx-state-banner"
            [ngClass]="item.transport_state_action"
            *ngIf="item.transport_state_action !== 'pendiente'"
          >
            {{ item.transport_state_action! | state }}
          </div>
        </mat-card-footer>
      </mat-card>
    </li>
  </ul>
</div>

<div *ngIf="route.children.length > 0">
  <router-outlet></router-outlet>
</div>
