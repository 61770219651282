import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizePipe } from './capitalize/capitalize-pipe.pipe';
import { StatePipe } from './state/state.pipe';

@NgModule({
  declarations: [
    CapitalizePipe,
    StatePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CapitalizePipe,
    StatePipe
  ]
})
export class PipesModule { }
